import merge from 'lodash/merge';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';

import { articleSchema } from '../../schemas';
import {
  GET_ARTICLE_INFO_PENDING,
  GET_ARTICLE_INFO_FULFILLED,
  GET_ARTICLE_INFO_REJECTED,
  GET_ARTICLE_PUSHES_PENDING,
  GET_ARTICLE_PUSHES_FULFILLED,
  GET_ARTICLE_PUSHES_REJECTED,
  GET_ARTICLE_PUSHES_STATS_PENDING,
  GET_ARTICLE_PUSHES_STATS_FULFILLED,
  GET_ARTICLE_PUSHES_STATS_REJECTED,
  GET_ARTICLE_REPLIES_PENDING,
  GET_ARTICLE_REPLIES_FULFILLED,
  GET_ARTICLE_REPLIES_REJECTED,
} from '../../types';

const INITIAL_STATE = {
  INFO: {
    article: null,
    entities: {},
    isFetching: false,
    error: null,
  },
  PUSHES: {
    article: null,
    data: [],
    stats: {
      data: {},
      isFetching: false,
    },
    offset: 0,
    limit: 0,
    isFetching: false,
    error: null,
  },
  REPLIES: {
    data: {
      id: null,
      reply_to: null, // (FIXME) should be camel case
      replies: [],
    },
    error: null,
  },
};

const info = (state = INITIAL_STATE.INFO, action) => {
  switch (action.type) {
    case GET_ARTICLE_INFO_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ARTICLE_INFO_FULFILLED: {
      const {
        data,
      } = action.payload;

      if (!data) {
        return INITIAL_STATE.INFO;
      }

      const {
        entities,
        result,
      } = normalize(data, articleSchema);

      return {
        entities: merge(state.entities, entities),
        article: result,
        isFetching: false,
      };
    }
    case GET_ARTICLE_INFO_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const pushes = (state = INITIAL_STATE.PUSHES, action) => {
  switch (action.type) {
    case GET_ARTICLE_PUSHES_PENDING:
      if (!state.article || action.meta.article !== state.article) {
        return {
          ...INITIAL_STATE.PUSHES,
          isFetching: true,
        };
      }
      return {
        ...state,
        isFetching: true,
      };
    case GET_ARTICLE_PUSHES_FULFILLED: {
      const {
        article,
        offset,
        limit,
      } = action.meta;

      const {
        data: {
          content,
          total,
        },
      } = action.payload;

      let data = content;

      if (article === state.article && offset > state.offset) {
        data = state.data.concat(data);
      }

      return {
        ...state,
        article,
        data,
        total,
        offset,
        limit,
        isFetching: false,
      };
    }
    case GET_ARTICLE_PUSHES_REJECTED:
      return {
        ...INITIAL_STATE.PUSHES,
        error: action.error,
        isFetching: false,
      };
    case GET_ARTICLE_PUSHES_STATS_PENDING:
      return {
        ...state,
        stats: {
          data: {},
          isFetching: true,
        },
      };
    case GET_ARTICLE_PUSHES_STATS_FULFILLED:
      return {
        ...state,
        stats: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    case GET_ARTICLE_PUSHES_STATS_REJECTED:
      return {
        ...state,
        stats: {
          error: action.error,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

const replies = (state = INITIAL_STATE.REPLIES, action) => {
  switch (action.type) {
    case GET_ARTICLE_REPLIES_PENDING:
      return {
        ...INITIAL_STATE.REPLIES,
        isFetching: true,
      };
    case GET_ARTICLE_REPLIES_FULFILLED:
      return {
        isFetching: false,
        data: action.payload.data,
      };
    case GET_ARTICLE_REPLIES_REJECTED:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  info,
  pushes,
  replies,
});
