import { combineReducers } from 'redux';

import {
  GET_AVG_POPULAR_TIME_FULFILLED,
  GET_AVG_POPULAR_TIME_PENDING,
  GET_AVG_POPULAR_TIME_REJECTED,
  GET_DCARD_DB_STATS_FULFILLED,
  GET_DCARD_DB_STATS_PENDING,
  GET_DCARD_DB_STATS_REJECTED,
  GET_DCARD_HOT_POSTS_FULFILLED,
  GET_DCARD_HOT_POSTS_PENDING,
  GET_DCARD_HOT_POSTS_REJECTED,
  GET_HOT_ARTICLES_FULFILLED,
  GET_HOT_ARTICLES_PENDING,
  GET_HOT_ARTICLES_REJECTED,
  GET_PTT_DB_STATS_FULFILLED,
  GET_PTT_DB_STATS_PENDING,
  GET_PTT_DB_STATS_REJECTED,
  GET_USER_GEO_DISTRIBUTIONS_FULFILLED,
  GET_USER_GEO_DISTRIBUTIONS_PENDING,
  GET_USER_GEO_DISTRIBUTIONS_REJECTED,
} from '../types';

const INITIAL_STATE = {
  // PTT
  AVG_POPULAR_TIME: {
    data: {},
    isFetching: false,
    error: null,
  },
  HOT_ARTICLES: {
    data: [],
    sinceDaysAgo: 1,
    offset: 0,
    limit: 0,
    isFetching: false,
    error: null,
  },
  PTT_DB_STATS: {
    data: {},
    isFetching: false,
    error: null,
  },
  USER_GEO_DISTRIBUTIONS: {
    data: [],
    isFetching: false,
    error: null,
  },
  // Dcard
  DCARD_DB_STATS: {
    data: {},
    isFetching: false,
    error: null,
  },
  DCARD_HOT_POSTS: {
    data: [],
    sinceDaysAgo: 1,
    offset: 0,
    limit: 0,
    isFetching: false,
    error: null,
  },
};

const avgPopularTime = (state = INITIAL_STATE.AVG_POPULAR_TIME, action) => {
  switch (action.type) {
    case GET_AVG_POPULAR_TIME_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_AVG_POPULAR_TIME_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_AVG_POPULAR_TIME_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const hotArticles = (state = INITIAL_STATE.HOT_ARTICLES, action) => {
  switch (action.type) {
    case GET_HOT_ARTICLES_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_HOT_ARTICLES_FULFILLED: {
      const {
        sinceDaysAgo,
        offset,
        limit,
      } = action.meta;
      const {
        data,
      } = action.payload;
      return {
        data,
        sinceDaysAgo,
        offset,
        limit,
        isFetching: false,
      };
    }
    case GET_HOT_ARTICLES_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const userGeoDistributions = (state = INITIAL_STATE.USER_GEO_DISTRIBUTIONS, action) => {
  switch (action.type) {
    case GET_USER_GEO_DISTRIBUTIONS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USER_GEO_DISTRIBUTIONS_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_USER_GEO_DISTRIBUTIONS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const pttDBStats = (state = INITIAL_STATE.PTT_DB_STATS, action) => {
  switch (action.type) {
    case GET_PTT_DB_STATS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PTT_DB_STATS_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_PTT_DB_STATS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const dcardDBStats = (state = INITIAL_STATE.DCARD_DB_STATS, action) => {
  switch (action.type) {
    case GET_DCARD_DB_STATS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_DCARD_DB_STATS_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_DCARD_DB_STATS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const dcardHotPosts = (state = INITIAL_STATE.DCARD_HOT_POSTS, action) => {
  switch (action.type) {
    case GET_DCARD_HOT_POSTS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_DCARD_HOT_POSTS_FULFILLED: {
      const {
        sinceDaysAgo,
        offset,
        limit,
      } = action.meta;
      const {
        data,
      } = action.payload;
      return {
        data,
        sinceDaysAgo,
        offset,
        limit,
        isFetching: false,
      };
    }
    case GET_DCARD_HOT_POSTS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  ptt: combineReducers({
    avgPopularTime,
    hotArticles,
    userGeoDistributions,
    dbStats: pttDBStats,
  }),
  dcard: combineReducers({
    dbStats: dcardDBStats,
    hotPosts: dcardHotPosts,
  }),
});
