import { combineReducers } from 'redux';

import {
  SET_DOCUMENT_TITLE,
  SET_META_TAGS,
} from '../types';

const INITIAL_STATE = {
  META_TAGS: [],
  TITLE: 'PTT Brain',
};

const title = (state = INITIAL_STATE.TITLE, action) => {
  if (action.type === SET_DOCUMENT_TITLE) {
    return action.payload;
  }
  return state;
};

const metaTags = (state = INITIAL_STATE.META_TAGS, action) => {
  if (action.type === SET_META_TAGS) {
    return action.payload;
  }
  return state;
};

export default combineReducers({
  metaTags,
  title,
});
