export const GET_ARTICLE_INFO = 'GET_ARTICLE_INFO';
export const GET_ARTICLE_INFO_PENDING = 'GET_ARTICLE_INFO_PENDING';
export const GET_ARTICLE_INFO_FULFILLED = 'GET_ARTICLE_INFO_FULFILLED';
export const GET_ARTICLE_INFO_REJECTED = 'GET_ARTICLE_INFO_REJECTED';
export const GET_ARTICLE_PUSHES = 'GET_ARTICLE_PUSHES';
export const GET_ARTICLE_PUSHES_PENDING = 'GET_ARTICLE_PUSHES_PENDING';
export const GET_ARTICLE_PUSHES_FULFILLED = 'GET_ARTICLE_PUSHES_FULFILLED';
export const GET_ARTICLE_PUSHES_REJECTED = 'GET_ARTICLE_PUSHES_REJECTED';
export const GET_ARTICLE_PUSHES_STATS = 'GET_ARTICLE_PUSHES_STATS';
export const GET_ARTICLE_PUSHES_STATS_PENDING = 'GET_ARTICLE_PUSHES_STATS_PENDING';
export const GET_ARTICLE_PUSHES_STATS_FULFILLED = 'GET_ARTICLE_PUSHES_STATS_FULFILLED';
export const GET_ARTICLE_PUSHES_STATS_REJECTED = 'GET_ARTICLE_PUSHES_STATS_REJECTED';
export const GET_ARTICLE_REPLIES = 'GET_ARTICLE_REPLIES';
export const GET_ARTICLE_REPLIES_PENDING = 'GET_ARTICLE_REPLIES_PENDING';
export const GET_ARTICLE_REPLIES_FULFILLED = 'GET_ARTICLE_REPLIES_FULFILLED';
export const GET_ARTICLE_REPLIES_REJECTED = 'GET_ARTICLE_REPLIES_REJECTED';
