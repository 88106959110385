import {
  schema,
} from 'normalizr';

const articleSchema = new schema.Entity('articles');
const boardSchema = new schema.Entity('boards');
const forumSchema = new schema.Entity('forums');
const personaSchema = new schema.Entity('personas');
const postSchema = new schema.Entity('posts');
const schoolSchema = new schema.Entity('schools');
const userSchema = new schema.Entity('users');

const searchSchema = {
  articles: [articleSchema],
  boards: [boardSchema],
  forums: [forumSchema],
  personas: [personaSchema],
  posts: [postSchema],
  schools: [schoolSchema],
  users: [userSchema],
};

export {
  articleSchema,
  boardSchema,
  forumSchema,
  personaSchema,
  postSchema,
  schoolSchema,
  searchSchema,
  userSchema,
};
