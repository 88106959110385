export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARDS_PENDING = 'GET_BOARDS_PENDING';
export const GET_BOARDS_FULFILLED = 'GET_BOARDS_FULFILLED';
export const GET_BOARDS_REJECTED = 'GET_BOARDS_REJECTED';
export const GET_BOARD_INFO = 'GET_BOARD_INFO';
export const GET_BOARD_INFO_PENDING = 'GET_BOARD_INFO_PENDING';
export const GET_BOARD_INFO_FULFILLED = 'GET_BOARD_INFO_FULFILLED';
export const GET_BOARD_INFO_REJECTED = 'GET_BOARD_INFO_REJECTED';
export const GET_BOARD_TRENDS = 'GET_BOARD_TRENDS';
export const GET_BOARD_TRENDS_PENDING = 'GET_BOARD_TRENDS_PENDING';
export const GET_BOARD_TRENDS_FULFILLED = 'GET_BOARD_TRENDS_FULFILLED';
export const GET_BOARD_TRENDS_REJECTED = 'GET_BOARD_TRENDS_REJECTED';
export const GET_BOARD_ARTICLES = 'GET_BOARD_ARTICLES';
export const GET_BOARD_ARTICLES_PENDING = 'GET_BOARD_ARTICLES_PENDING';
export const GET_BOARD_ARTICLES_FULFILLED = 'GET_BOARD_ARTICLES_FULFILLED';
export const GET_BOARD_ARTICLES_REJECTED = 'GET_BOARD_ARTICLES_REJECTED';
export const GET_BOARD_HOT_ARTICLES = 'GET_BOARD_HOT_ARTICLES';
export const GET_BOARD_HOT_ARTICLES_PENDING = 'GET_BOARD_HOT_ARTICLES_PENDING';
export const GET_BOARD_HOT_ARTICLES_FULFILLED = 'GET_BOARD_HOT_ARTICLES_FULFILLED';
export const GET_BOARD_HOT_ARTICLES_REJECTED = 'GET_BOARD_HOT_ARTICLES_REJECTED';
export const GET_BOARD_TOP_USERS = 'GET_BOARD_TOP_USERS';
export const GET_BOARD_TOP_USERS_PENDING = 'GET_BOARD_TOP_USERS_PENDING';
export const GET_BOARD_TOP_USERS_FULFILLED = 'GET_BOARD_TOP_USERS_FULFILLED';
export const GET_BOARD_TOP_USERS_REJECTED = 'GET_BOARD_TOP_USERS_REJECTED';
export const GET_BOARD_USER_COUNT = 'GET_BOARD_USER_COUNT';
export const GET_BOARD_USER_COUNT_PENDING = 'GET_BOARD_USER_COUNT_PENDING';
export const GET_BOARD_USER_COUNT_FULFILLED = 'GET_BOARD_USER_COUNT_FULFILLED';
export const GET_BOARD_USER_COUNT_REJECTED = 'GET_BOARD_USER_COUNT_REJECTED';
