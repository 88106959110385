import merge from 'lodash/merge';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';

import { personaSchema } from '../../schemas';
import {
  GET_PERSONA_DAILY_ACTIVITIES_DETAILS_FULFILLED,
  GET_PERSONA_DAILY_ACTIVITIES_DETAILS_PENDING,
  GET_PERSONA_DAILY_ACTIVITIES_DETAILS_REJECTED,
  GET_PERSONA_INFO_FULFILLED,
  GET_PERSONA_INFO_PENDING,
  GET_PERSONA_INFO_REJECTED,
  GET_PERSONA_INTERESTS_FULFILLED,
  GET_PERSONA_INTERESTS_PENDING,
  GET_PERSONA_INTERESTS_REJECTED,
  GET_PERSONA_POSTS_FULFILLED,
  GET_PERSONA_POSTS_PENDING,
  GET_PERSONA_POSTS_REJECTED,
  GET_PERSONA_RECENT_ACTIVITIES_FULFILLED,
  GET_PERSONA_RECENT_ACTIVITIES_PENDING,
  GET_PERSONA_RECENT_ACTIVITIES_REJECTED,
} from '../../types';

const INITIAL_STATE = {
  INFO: {
    id: null,
    entities: {},
    isFetching: false,
    error: null,
  },
  RECENT_ACTIVITIES: {
    data: {
      date: [],
      times: [],
    },
    isFetching: false,
    error: null,
  },
  POSTS: {
    persona: null,
    data: [],
    total: 0,
    offset: 0,
    limit: 0,
    isFetching: false,
    error: null,
  },
  INTERESTS: {
    data: [],
    isFetching: false,
    error: null,
  },
  DAILY_ACTIVITIES_DETAILS: {
    data: {
      posts: [],
      comments: [],
    },
    isFetching: false,
    error: null,
  },
};

const info = (state = INITIAL_STATE.INFO, action) => {
  switch (action.type) {
    case GET_PERSONA_INFO_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PERSONA_INFO_FULFILLED: {
      const {
        data,
      } = action.payload;
      if (!data) {
        return INITIAL_STATE.INFO;
      }
      const {
        entities,
        result,
      } = normalize(data, personaSchema);
      return {
        entities: merge(state.entities, entities),
        id: result,
        isFetching: false,
      };
    }
    case GET_PERSONA_INFO_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const recentActivities = (state = INITIAL_STATE.RECENT_ACTIVITIES, action) => {
  switch (action.type) {
    case GET_PERSONA_RECENT_ACTIVITIES_PENDING:
      return {
        ...INITIAL_STATE.RECENT_ACTIVITIES,
        isFetching: true,
      };
    case GET_PERSONA_RECENT_ACTIVITIES_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_PERSONA_RECENT_ACTIVITIES_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const posts = (state = INITIAL_STATE.POSTS, action) => {
  switch (action.type) {
    case GET_PERSONA_POSTS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PERSONA_POSTS_FULFILLED: {
      const {
        persona,
        offset,
        limit,
      } = action.meta;
      const {
        data: {
          total,
          content,
        },
      } = action.payload;
      return {
        persona,
        data: content,
        total,
        offset,
        limit,
        isFetching: false,
      };
    }
    case GET_PERSONA_POSTS_REJECTED:
      return {
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const interests = (state = INITIAL_STATE.INTERESTS, action) => {
  switch (action.type) {
    case GET_PERSONA_INTERESTS_PENDING:
      return {
        ...INITIAL_STATE.INTERESTS,
        isFetching: true,
      };
    case GET_PERSONA_INTERESTS_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_PERSONA_INTERESTS_REJECTED:
      return {
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const dailyActivitiesDetails = (state = INITIAL_STATE.DAILY_ACTIVITIES_DETAILS, action) => {
  switch (action.type) {
    case GET_PERSONA_DAILY_ACTIVITIES_DETAILS_PENDING:
      return {
        ...INITIAL_STATE.DAILY_ACTIVITIES_DETAILS,
        isFetching: true,
      };
    case GET_PERSONA_DAILY_ACTIVITIES_DETAILS_FULFILLED:
      return {
        data: action.payload.data,
        isFetching: false,
      };
    case GET_PERSONA_DAILY_ACTIVITIES_DETAILS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  dailyActivitiesDetails,
  info,
  interests,
  posts,
  recentActivities,
});
