export const GET_SCHOOL_HOT_POSTS = 'GET_SCHOOL_HOT_POSTS';
export const GET_SCHOOL_HOT_POSTS_FULFILLED = 'GET_SCHOOL_HOT_POSTS_FULFILLED';
export const GET_SCHOOL_HOT_POSTS_PENDING = 'GET_SCHOOL_HOT_POSTS_PENDING';
export const GET_SCHOOL_HOT_POSTS_REJECTED = 'GET_SCHOOL_HOT_POSTS_REJECTED';
export const GET_SCHOOL_INFO = 'GET_SCHOOL_INFO';
export const GET_SCHOOL_INFO_FULFILLED = 'GET_SCHOOL_INFO_FULFILLED';
export const GET_SCHOOL_INFO_PENDING = 'GET_SCHOOL_INFO_PENDING';
export const GET_SCHOOL_INFO_REJECTED = 'GET_SCHOOL_INFO_REJECTED';
export const GET_SCHOOL_INTERESTS = 'GET_SCHOOL_INTERESTS';
export const GET_SCHOOL_INTERESTS_FULFILLED = 'GET_SCHOOL_INTERESTS_FULFILLED';
export const GET_SCHOOL_INTERESTS_PENDING = 'GET_SCHOOL_INTERESTS_PENDING';
export const GET_SCHOOL_INTERESTS_REJECTED = 'GET_SCHOOL_INTERESTS_REJECTED';
export const GET_SCHOOL_POSTS = 'GET_SCHOOL_POSTS';
export const GET_SCHOOL_POSTS_FULFILLED = 'GET_SCHOOL_POSTS_FULFILLED';
export const GET_SCHOOL_POSTS_PENDING = 'GET_SCHOOL_POSTS_PENDING';
export const GET_SCHOOL_POSTS_REJECTED = 'GET_SCHOOL_POSTS_REJECTED';
export const GET_SCHOOL_RECENT_ACTIVITIES = 'GET_SCHOOL_RECENT_ACTIVITIES';
export const GET_SCHOOL_RECENT_ACTIVITIES_FULFILLED = 'GET_SCHOOL_RECENT_ACTIVITIES_FULFILLED';
export const GET_SCHOOL_RECENT_ACTIVITIES_PENDING = 'GET_SCHOOL_RECENT_ACTIVITIES_PENDING';
export const GET_SCHOOL_RECENT_ACTIVITIES_REJECTED = 'GET_SCHOOL_RECENT_ACTIVITIES_REJECTED';
export const GET_SCHOOL_TOPICS = 'GET_SCHOOL_TOPICS';
export const GET_SCHOOL_TOPICS_FULFILLED = 'GET_SCHOOL_TOPICS_FULFILLED';
export const GET_SCHOOL_TOPICS_PENDING = 'GET_SCHOOL_TOPICS_PENDING';
export const GET_SCHOOL_TOPICS_REJECTED = 'GET_SCHOOL_TOPICS_REJECTED';
