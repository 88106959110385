import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux';
import { reactReduxFirebase } from 'react-redux-firebase';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { createWrapper, Context, HYDRATE } from 'next-redux-wrapper';

import firebase from '../Firebase';
import createRootReducer from '../reducers';

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const enhancer = composeEnhancers(
  reactReduxFirebase(firebase),
  responsiveStoreEnhancer,
  applyMiddleware(thunkMiddleware, promiseMiddleware),
);

// create a makeStore function
const makeStore = context => createStore(createRootReducer, context, enhancer);

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, { debug: false });
