import HyperDX from '@hyperdx/browser';
import React, { FC, useEffect, useState } from 'react';
import Script from 'next/script';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
// @ts-ignore
import ScrollUpButton from 'react-scroll-up-button';

import * as config from '../src/config';
import { wrapper } from '../src/store';

import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.css';

import type { AppProps } from 'next/app';

if (config.HYPERDX_API_KEY) {
  HyperDX.init({
    advancedNetworkCapture: false,
    apiKey: config.HYPERDX_API_KEY,
    consoleCapture: true,
    maskAllInputs: false,
    maskAllText: false,
    service: 'pttbrain-app',
    tracePropagationTargets: [
      /localhost/i,
      /pttbrain-api\.herokuapp\.com/i,
      /pttbrain\.com/i,
    ],
  });

  HyperDX.attachToReactErrorBoundary(ErrorBoundary);
}

const MyApp: FC<AppProps> = ({ Component, ...rest }) => {
  const [isMounted, setIsMounted] = useState(false);

  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-57Y04TQGFN"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-57Y04TQGFN');
        `}
      </Script>
      <Provider store={store}>
        <ToastContainer position="bottom-right" theme="light" />
        <Component {...props.pageProps} />
        <ScrollUpButton
          StopPosition={0}
          ShowAtPosition={150}
          EasingType="easeOutCubic"
          AnimationDuration={500}
          style={{
            borderRadius: '50%',
            height: '60px',
            width: '60px',
          }}
          ToggledStyle={{ right: '22px' }}
        />
      </Provider>
    </ErrorBoundary>
  );
};

export default MyApp;
