export const GET_PERSONA_DAILY_ACTIVITIES_DETAILS = 'GET_PERSONA_DAILY_ACTIVITIES_DETAILS';
export const GET_PERSONA_DAILY_ACTIVITIES_DETAILS_FULFILLED = 'GET_PERSONA_DAILY_ACTIVITIES_DETAILS_FULFILLED';
export const GET_PERSONA_DAILY_ACTIVITIES_DETAILS_PENDING = 'GET_PERSONA_DAILY_ACTIVITIES_DETAILS_PENDING';
export const GET_PERSONA_DAILY_ACTIVITIES_DETAILS_REJECTED = 'GET_PERSONA_DAILY_ACTIVITIES_DETAILS_REJECTED';
export const GET_PERSONA_INFO = 'GET_PERSONA_INFO';
export const GET_PERSONA_INFO_FULFILLED = 'GET_PERSONA_INFO_FULFILLED';
export const GET_PERSONA_INFO_PENDING = 'GET_PERSONA_INFO_PENDING';
export const GET_PERSONA_INFO_REJECTED = 'GET_PERSONA_INFO_REJECTED';
export const GET_PERSONA_INTERESTS = 'GET_PERSONA_INTERESTS';
export const GET_PERSONA_INTERESTS_FULFILLED = 'GET_PERSONA_INTERESTS_FULFILLED';
export const GET_PERSONA_INTERESTS_PENDING = 'GET_PERSONA_INTERESTS_PENDING';
export const GET_PERSONA_INTERESTS_REJECTED = 'GET_PERSONA_INTERESTS_REJECTED';
export const GET_PERSONA_POSTS = 'GET_PERSONA_POSTS';
export const GET_PERSONA_POSTS_FULFILLED = 'GET_PERSONA_POSTS_FULFILLED';
export const GET_PERSONA_POSTS_PENDING = 'GET_PERSONA_POSTS_PENDING';
export const GET_PERSONA_POSTS_REJECTED = 'GET_PERSONA_POSTS_REJECTED';
export const GET_PERSONA_RECENT_ACTIVITIES = 'GET_PERSONA_RECENT_ACTIVITIES';
export const GET_PERSONA_RECENT_ACTIVITIES_FULFILLED = 'GET_PERSONA_RECENT_ACTIVITIES_FULFILLED';
export const GET_PERSONA_RECENT_ACTIVITIES_PENDING = 'GET_PERSONA_RECENT_ACTIVITIES_PENDING';
export const GET_PERSONA_RECENT_ACTIVITIES_REJECTED = 'GET_PERSONA_RECENT_ACTIVITIES_REJECTED';
