export const EMAIL_CHANGED = 'EMAIL_CHANGED';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_FULFILLED = 'LOGIN_USER_FULFILLED';
export const LOGIN_USER_REJECTED = 'LOGIN_USER_REJECTED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_PENDING = 'LOGOUT_USER_PENDING';
export const LOGOUT_USER_FULFILLED = 'LOGOUT_USER_FULFILLED';
export const LOGOUT_USER_REJECTED = 'LOGOUT_USER_REJECTED';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_PENDING = 'SIGNUP_USER_PENDING';
export const SIGNUP_USER_FULFILLED = 'SIGNUP_USER_FULFILLED';
export const SIGNUP_USER_REJECTED = 'SIGNUP_USER_REJECTED';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING';
export const FORGOT_PASSWORD_FULFILLED = 'FORGOT_PASSWORD_FULFILLED';
export const FORGOT_PASSWORD_REJECTED = 'FORGOT_PASSWORD_REJECTED';
export const GET_ME = 'GET_ME';
export const GET_ME_PENDING = 'GET_ME_PENDING';
export const GET_ME_FULFILLED = 'GET_ME_FULFILLED';
export const GET_ME_REJECTED = 'GET_ME_REJECTED';
