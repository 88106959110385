export const GET_FORUMS = 'GET_FORUMS';
export const GET_FORUMS_FULFILLED = 'GET_FORUMS_FULFILLED';
export const GET_FORUMS_PENDING = 'GET_FORUMS_PENDING';
export const GET_FORUMS_REJECTED = 'GET_FORUMS_REJECTED';
export const GET_FORUM_CHART = 'GET_FORUM_CHART';
export const GET_FORUM_CHART_FULFILLED = 'GET_FORUM_CHART_FULFILLED';
export const GET_FORUM_CHART_PENDING = 'GET_FORUM_CHART_PENDING';
export const GET_FORUM_CHART_REJECTED = 'GET_FORUM_CHART_REJECTED';
export const GET_FORUM_GENDER_RATIO = 'GET_FORUM_GENDER_RATIO';
export const GET_FORUM_GENDER_RATIO_FULFILLED = 'GET_FORUM_GENDER_RATIO_FULFILLED';
export const GET_FORUM_GENDER_RATIO_PENDING = 'GET_FORUM_GENDER_RATIO_PENDING';
export const GET_FORUM_GENDER_RATIO_REJECTED = 'GET_FORUM_GENDER_RATIO_REJECTED';
export const GET_FORUM_HOT_POSTS = 'GET_FORUM_HOT_POSTS';
export const GET_FORUM_HOT_POSTS_FULFILLED = 'GET_FORUM_HOT_POSTS_FULFILLED';
export const GET_FORUM_HOT_POSTS_PENDING = 'GET_FORUM_HOT_POSTS_PENDING';
export const GET_FORUM_HOT_POSTS_REJECTED = 'GET_FORUM_HOT_POSTS_REJECTED';
export const GET_FORUM_INFO = 'GET_FORUM_INFO';
export const GET_FORUM_INFO_FULFILLED = 'GET_FORUM_INFO_FULFILLED';
export const GET_FORUM_INFO_PENDING = 'GET_FORUM_INFO_PENDING';
export const GET_FORUM_INFO_REJECTED = 'GET_FORUM_INFO_REJECTED';
export const GET_FORUM_POSTS = 'GET_FORUM_POSTS';
export const GET_FORUM_POSTS_FULFILLED = 'GET_FORUM_POSTS_FULFILLED';
export const GET_FORUM_POSTS_PENDING = 'GET_FORUM_POSTS_PENDING';
export const GET_FORUM_POSTS_REJECTED = 'GET_FORUM_POSTS_REJECTED';
export const GET_FORUM_SCHOOLS = 'GET_FORUM_SCHOOLS';
export const GET_FORUM_SCHOOLS_FULFILLED = 'GET_FORUM_SCHOOLS_FULFILLED';
export const GET_FORUM_SCHOOLS_PENDING = 'GET_FORUM_SCHOOLS_PENDING';
export const GET_FORUM_SCHOOLS_REJECTED = 'GET_FORUM_SCHOOLS_REJECTED';
export const GET_FORUM_SUBSCRIPTION_COUNTS = 'GET_FORUM_SUBSCRIPTION_COUNTS';
export const GET_FORUM_SUBSCRIPTION_COUNTS_FULFILLED = 'GET_FORUM_SUBSCRIPTION_COUNTS_FULFILLED';
export const GET_FORUM_SUBSCRIPTION_COUNTS_PENDING = 'GET_FORUM_SUBSCRIPTION_COUNTS_PENDING';
export const GET_FORUM_SUBSCRIPTION_COUNTS_REJECTED = 'GET_FORUM_SUBSCRIPTION_COUNTS_REJECTED';
export const GET_FORUM_TOPICS = 'GET_FORUM_TOPICS';
export const GET_FORUM_TOPICS_FULFILLED = 'GET_FORUM_TOPICS_FULFILLED';
export const GET_FORUM_TOPICS_PENDING = 'GET_FORUM_TOPICS_PENDING';
export const GET_FORUM_TOPICS_REJECTED = 'GET_FORUM_TOPICS_REJECTED';
export const GET_FORUM_TRENDS = 'GET_FORUM_TRENDS';
export const GET_FORUM_TRENDS_FULFILLED = 'GET_FORUM_TRENDS_FULFILLED';
export const GET_FORUM_TRENDS_PENDING = 'GET_FORUM_TRENDS_PENDING';
export const GET_FORUM_TRENDS_REJECTED = 'GET_FORUM_TRENDS_REJECTED';
