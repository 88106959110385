import merge from 'lodash/merge';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';

import { userSchema } from '../../schemas';
import {
  GET_RELATED_USER_IDS_FULFILLED,
  GET_RELATED_USER_IDS_PENDING,
  GET_RELATED_USER_IDS_REJECTED,
  GET_USER_ARTICLES_FULFILLED,
  GET_USER_ARTICLES_PENDING,
  GET_USER_ARTICLES_REJECTED,
  GET_USER_DAILY_ACTIVITIES_DETAILS_FULFILLED,
  GET_USER_DAILY_ACTIVITIES_DETAILS_PENDING,
  GET_USER_DAILY_ACTIVITIES_DETAILS_REJECTED,
  GET_USER_FANS_FULFILLED,
  GET_USER_FANS_PENDING,
  GET_USER_FANS_REJECTED,
  GET_USER_INFO_FULFILLED,
  GET_USER_INFO_PENDING,
  GET_USER_INFO_REJECTED,
  GET_USER_INTERESTS_FULFILLED,
  GET_USER_INTERESTS_PENDING,
  GET_USER_INTERESTS_REJECTED,
  GET_USER_LOCATIONS_FULFILLED,
  GET_USER_LOCATIONS_PENDING,
  GET_USER_LOCATIONS_REJECTED,
  GET_USER_PUSHES_STATS_FULFILLED,
  GET_USER_PUSHES_STATS_PENDING,
  GET_USER_PUSHES_STATS_REJECTED,
  GET_USER_RECENT_ACTIVITIES_FULFILLED,
  GET_USER_RECENT_ACTIVITIES_PENDING,
  GET_USER_RECENT_ACTIVITIES_REJECTED,
} from '../../types';

const INITIAL_STATE = {
  INFO: {
    user: null,
    entities: {},
    isFetching: false,
    error: null,
  },
  LOCATIONS: {
    data: [],
    isFetching: false,
    error: null,
  },
  INTERESTS: {
    data: {
      pushesBoardsBreakdown: [],
      pushesCategoriesBreakdown: [],
    },
    isFetching: false,
    error: null,
  },
  PUSHES_STATS: {
    data: [],
    isFetching: false,
    error: null,
  },
  RECENT_ACTIVITIES: {
    data: {
      date: [],
      times: [],
    },
    isFetching: false,
    error: null,
  },
  DAILY_ACTIVITIES_DETAILS: {
    data: {
      articles: [],
      pushes: [],
    },
    isFetching: false,
    error: null,
  },
  ARTICLES: {
    user: null,
    data: [],
    offset: 0,
    limit: 0,
    isFetching: false,
    error: null,
  },
  RELATED_IDS: {
    data: [],
    isFetching: false,
    error: null,
  },
  FANS: {
    data: [],
    isFetching: false,
    error: null,
  },
};

const info = (state = INITIAL_STATE.INFO, action) => {
  switch (action.type) {
    case GET_USER_INFO_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USER_INFO_FULFILLED: {
      const {
        data,
      } = action.payload;
      if (!data) {
        return INITIAL_STATE.INFO;
      }
      const {
        entities,
        result,
      } = normalize(data, userSchema);
      return {
        entities: merge(state.entities, entities),
        user: result,
        isFetching: false,
      };
    }
    case GET_USER_INFO_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const locations = (state = INITIAL_STATE.LOCATIONS, action) => {
  switch (action.type) {
    case GET_USER_LOCATIONS_PENDING:
      return {
        ...INITIAL_STATE.LOCATIONS,
        isFetching: true,
      };
    case GET_USER_LOCATIONS_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_USER_LOCATIONS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const interests = (state = INITIAL_STATE.INTERESTS, action) => {
  switch (action.type) {
    case GET_USER_INTERESTS_PENDING:
      return {
        ...INITIAL_STATE.INTERESTS,
        isFetching: true,
      };
    case GET_USER_INTERESTS_FULFILLED: {
      const [
        pushesBoardsBreakdown,
        pushesCategoriesBreakdown,
      ] = action.payload;
      return {
        data: {
          pushesBoardsBreakdown: pushesBoardsBreakdown.data,
          pushesCategoriesBreakdown: pushesCategoriesBreakdown.data,
        },
        isFetching: false,
      };
    }
    case GET_USER_INTERESTS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const pushesStats = (state = INITIAL_STATE.PUSHES_STATS, action) => {
  switch (action.type) {
    case GET_USER_PUSHES_STATS_PENDING:
      return {
        ...INITIAL_STATE.PUSHES_STATS,
        isFetching: true,
      };
    case GET_USER_PUSHES_STATS_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_USER_PUSHES_STATS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const recentActivities = (state = INITIAL_STATE.RECENT_ACTIVITIES, action) => {
  switch (action.type) {
    case GET_USER_RECENT_ACTIVITIES_PENDING:
      return {
        ...INITIAL_STATE.RECENT_ACTIVITIES,
        isFetching: true,
      };
    case GET_USER_RECENT_ACTIVITIES_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_USER_RECENT_ACTIVITIES_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const dailyActivitiesDetails = (state = INITIAL_STATE.DAILY_ACTIVITIES_DETAILS, action) => {
  switch (action.type) {
    case GET_USER_DAILY_ACTIVITIES_DETAILS_PENDING:
      return {
        ...INITIAL_STATE.DAILY_ACTIVITIES_DETAILS,
        isFetching: true,
      };
    case GET_USER_DAILY_ACTIVITIES_DETAILS_FULFILLED:
      return {
        data: action.payload.data,
        isFetching: false,
      };
    case GET_USER_DAILY_ACTIVITIES_DETAILS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const articles = (state = INITIAL_STATE.ARTICLES, action) => {
  switch (action.type) {
    case GET_USER_ARTICLES_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USER_ARTICLES_FULFILLED: {
      const {
        user,
        offset,
        limit,
      } = action.meta;
      const {
        data,
      } = action.payload;
      return {
        user,
        data,
        offset,
        limit,
        isFetching: false,
      };
    }
    case GET_USER_ARTICLES_REJECTED:
      return {
        ...INITIAL_STATE.ARTICLES,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const relatedIDs = (state = INITIAL_STATE.RELATED_IDS, action) => {
  switch (action.type) {
    case GET_RELATED_USER_IDS_PENDING:
      return {
        ...INITIAL_STATE.RELATED_IDS,
        isFetching: true,
      };
    case GET_RELATED_USER_IDS_FULFILLED:
      return {
        data: action.payload.data,
        isFetching: false,
      };
    case GET_RELATED_USER_IDS_REJECTED:
      return {
        ...INITIAL_STATE.RELATED_IDS,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const fans = (state = INITIAL_STATE.FANS, action) => {
  switch (action.type) {
    case GET_USER_FANS_PENDING:
      return {
        ...INITIAL_STATE.FANS,
        isFetching: true,
      };
    case GET_USER_FANS_FULFILLED:
      return {
        data: action.payload.data,
        isFetching: false,
      };
    case GET_USER_FANS_REJECTED:
      return {
        ...INITIAL_STATE.FANS,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  articles,
  dailyActivitiesDetails,
  fans,
  info,
  interests,
  locations,
  pushesStats,
  recentActivities,
  relatedIDs,
});
