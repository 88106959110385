import merge from 'lodash/merge';
import { normalize } from 'normalizr';

import { searchSchema } from '../schemas';
import {
  SEARCH_ALL_PENDING,
  SEARCH_ALL_FULFILLED,
  SEARCH_ALL_REJECTED,
} from '../types';

const INITIAL_STATE = {
  boards: [],
  users: [],
  articles: [],
  personas: [],
  schools: [],
  forums: [],
  posts: [],
  entities: {},
  error: null,
  offset: 0,
  limit: 0,
  q: null,
  platform: null,
  isFetching: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_ALL_PENDING: {
      const {
        platform,
      } = action.meta;

      if (platform !== state.platform) {
        return {
          ...INITIAL_STATE,
          platform,
          isFetching: true,
        };
      }

      return {
        ...state,
        isFetching: true,
      };
    }
    case SEARCH_ALL_FULFILLED: {
      const {
        q: preSearchTerm,
        boards,
        articles,
        users,
        personas,
        schools,
        forums,
        posts,
      } = state;

      const {
        platform,
        q: nextSearchTerm,
        offset,
        limit,
      } = action.meta;

      const {
        entities,
        result,
      } = normalize(action.payload.data, searchSchema);

      // Concat new results
      if (
        preSearchTerm === nextSearchTerm
        && offset > 0
      ) {
        return {
          entities: merge(state.entities, entities),
          ...(platform === 'ptt' && {
            boards: [...boards, ...result.boards],
            articles: [...articles, ...result.articles],
            users: [...users, ...result.users],
          }),
          ...(platform === 'dcard' && {
            personas: [...personas, ...result.personas],
            forums: [...forums, ...result.forums],
            posts: [...posts, ...result.posts],
            schools: [...schools, ...result.schools],
          }),
          platform,
          q: nextSearchTerm,
          offset,
          limit,
          isFetching: false,
        };
      }
      return {
        entities: merge(state.entities, entities),
        ...result,
        platform,
        q: nextSearchTerm,
        offset,
        limit,
        isFetching: false,
      };
    }
    case SEARCH_ALL_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};
