import { combineReducers } from 'redux';

import {
  GET_DASHBOARD_ALL_FOLLOWINGS_PENDING,
  GET_DASHBOARD_ALL_FOLLOWINGS_FULFILLED,
  GET_DASHBOARD_ALL_FOLLOWINGS_REJECTED,
} from '../types';

const INITIAL_STATE = {
  FOLLOWINGS: {
    data: [],
    isFetching: false,
    error: null,
  },
};

const followings = (state = INITIAL_STATE.FOLLOWINGS, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ALL_FOLLOWINGS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_DASHBOARD_ALL_FOLLOWINGS_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_DASHBOARD_ALL_FOLLOWINGS_REJECTED: {
      const {
        message,
      } = action.payload;
      return {
        message,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  followings,
});
