import merge from 'lodash/merge';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';

import { boardSchema } from '../../schemas';
import {
  GET_BOARDS_FULFILLED,
  GET_BOARDS_PENDING,
  GET_BOARDS_REJECTED,
  GET_BOARD_ARTICLES_FULFILLED,
  GET_BOARD_ARTICLES_PENDING,
  GET_BOARD_ARTICLES_REJECTED,
  GET_BOARD_HOT_ARTICLES_FULFILLED,
  GET_BOARD_HOT_ARTICLES_PENDING,
  GET_BOARD_HOT_ARTICLES_REJECTED,
  GET_BOARD_INFO_FULFILLED,
  GET_BOARD_INFO_PENDING,
  GET_BOARD_INFO_REJECTED,
  GET_BOARD_TOP_USERS_FULFILLED,
  GET_BOARD_TOP_USERS_PENDING,
  GET_BOARD_TOP_USERS_REJECTED,
  GET_BOARD_TRENDS_FULFILLED,
  GET_BOARD_TRENDS_PENDING,
  GET_BOARD_TRENDS_REJECTED,
  GET_BOARD_USER_COUNT_FULFILLED,
  GET_BOARD_USER_COUNT_PENDING,
  GET_BOARD_USER_COUNT_REJECTED,
} from '../../types';

const INITIAL_STATE = {
  ALL: {
    data: [],
    isFetching: false,
    error: null,
  },
  INFO: {
    board: null,
    entities: {},
    isFetching: false,
    error: null,
  },
  TRENDS: {
    data: [],
    isFetching: false,
    error: null,
  },
  ARTICLES: {
    board: null,
    data: [],
    offset: 0,
    limit: 0,
    isFetching: false,
    error: null,
  },
  HOT_ARTICLES: {
    board: null,
    data: [],
    sinceDaysAgo: 1,
    offset: 0,
    limit: 0,
    isFetching: false,
    error: null,
  },
  TOP_USERS: {
    data: [],
    isFetching: false,
    error: null,
  },
  USER_COUNT: {
    data: [],
    isFetching: false,
    error: null,
  },
};

const all = (state = INITIAL_STATE.ALL, action) => {
  switch (action.type) {
    case GET_BOARDS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_BOARDS_FULFILLED: {
      const {
        data,
      } = action.payload;

      return {
        data,
        isFetching: false,
      };
    }
    case GET_BOARDS_REJECTED:
      return {
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const info = (state = INITIAL_STATE.INFO, action) => {
  switch (action.type) {
    case GET_BOARD_INFO_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_BOARD_INFO_FULFILLED: {
      const {
        data,
      } = action.payload;
      if (!data) {
        return INITIAL_STATE.INFO;
      }
      const {
        entities,
        result,
      } = normalize(data, boardSchema);

      return {
        entities: merge(state.entities, entities),
        board: result,
        isFetching: false,
      };
    }
    case GET_BOARD_INFO_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const trends = (state = INITIAL_STATE.TRENDS, action) => {
  switch (action.type) {
    case GET_BOARD_TRENDS_PENDING:
      return {
        ...INITIAL_STATE.TRENDS,
        isFetching: true,
      };
    case GET_BOARD_TRENDS_FULFILLED: {
      const {
        data,
      } = action.payload;

      return {
        data,
        isFetching: false,
      };
    }
    case GET_BOARD_TRENDS_REJECTED:
      return {
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const articles = (state = INITIAL_STATE.ARTICLES, action) => {
  switch (action.type) {
    case GET_BOARD_ARTICLES_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_BOARD_ARTICLES_FULFILLED: {
      const {
        board,
        offset,
        limit,
      } = action.meta;
      const {
        data,
      } = action.payload;
      return {
        board,
        data,
        offset,
        limit,
        isFetching: false,
      };
    }
    case GET_BOARD_ARTICLES_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const hotArticles = (state = INITIAL_STATE.HOT_ARTICLES, action) => {
  switch (action.type) {
    case GET_BOARD_HOT_ARTICLES_PENDING:
      return {
        ...INITIAL_STATE.HOT_ARTICLES,
        isFetching: true,
      };
    case GET_BOARD_HOT_ARTICLES_FULFILLED: {
      const {
        board,
        sinceDaysAgo,
        offset,
        limit,
      } = action.meta;
      const {
        data,
      } = action.payload;
      return {
        board,
        data,
        sinceDaysAgo,
        offset,
        limit,
        isFetching: false,
      };
    }
    case GET_BOARD_HOT_ARTICLES_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const topUsers = (state = INITIAL_STATE.TOP_USERS, action) => {
  switch (action.type) {
    case GET_BOARD_TOP_USERS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_BOARD_TOP_USERS_FULFILLED: {
      const {
        data,
      } = action.payload;

      return {
        data,
        isFetching: false,
      };
    }
    case GET_BOARD_TOP_USERS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const userCount = (state = INITIAL_STATE.USER_COUNT, action) => {
  switch (action.type) {
    case GET_BOARD_USER_COUNT_PENDING:
      return {
        ...INITIAL_STATE.USER_COUNT,
        isFetching: true,
      };
    case GET_BOARD_USER_COUNT_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_BOARD_USER_COUNT_REJECTED:
      return {
        ...INITIAL_STATE.USER_COUNT,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  all,
  articles,
  hotArticles,
  info,
  topUsers,
  trends,
  userCount,
});
