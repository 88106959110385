export const GET_AVG_POPULAR_TIME = 'GET_AVG_POPULAR_TIME';
export const GET_AVG_POPULAR_TIME_FULFILLED = 'GET_AVG_POPULAR_TIME_FULFILLED';
export const GET_AVG_POPULAR_TIME_PENDING = 'GET_AVG_POPULAR_TIME_PENDING';
export const GET_AVG_POPULAR_TIME_REJECTED = 'GET_AVG_POPULAR_TIME_REJECTED';
export const GET_DCARD_DB_STATS = 'GET_DCARD_DB_STATS';
export const GET_DCARD_DB_STATS_FULFILLED = 'GET_DCARD_DB_STATS_FULFILLED';
export const GET_DCARD_DB_STATS_PENDING = 'GET_DCARD_DB_STATS_PENDING';
export const GET_DCARD_DB_STATS_REJECTED = 'GET_DCARD_DB_STATS_REJECTED';
export const GET_DCARD_HOT_POSTS = 'GET_DCARD_HOT_POSTS';
export const GET_DCARD_HOT_POSTS_FULFILLED = 'GET_DCARD_HOT_POSTS_FULFILLED';
export const GET_DCARD_HOT_POSTS_PENDING = 'GET_DCARD_HOT_POSTS_PENDING';
export const GET_DCARD_HOT_POSTS_REJECTED = 'GET_DCARD_HOT_POSTS_REJECTED';
export const GET_HOT_ARTICLES = 'GET_HOT_ARTICLES';
export const GET_HOT_ARTICLES_FULFILLED = 'GET_HOT_ARTICLES_FULFILLED';
export const GET_HOT_ARTICLES_PENDING = 'GET_HOT_ARTICLES_PENDING';
export const GET_HOT_ARTICLES_REJECTED = 'GET_HOT_ARTICLES_REJECTED';
export const GET_PTT_DB_STATS = 'GET_PTT_DB_STATS';
export const GET_PTT_DB_STATS_FULFILLED = 'GET_PTT_DB_STATS_FULFILLED';
export const GET_PTT_DB_STATS_PENDING = 'GET_PTT_DB_STATS_PENDING';
export const GET_PTT_DB_STATS_REJECTED = 'GET_PTT_DB_STATS_REJECTED';
export const GET_USER_GEO_DISTRIBUTIONS = 'GET_USER_GEO_DISTRIBUTIONS';
export const GET_USER_GEO_DISTRIBUTIONS_FULFILLED = 'GET_USER_GEO_DISTRIBUTIONS_FULFILLED';
export const GET_USER_GEO_DISTRIBUTIONS_PENDING = 'GET_USER_GEO_DISTRIBUTIONS_PENDING';
export const GET_USER_GEO_DISTRIBUTIONS_REJECTED = 'GET_USER_GEO_DISTRIBUTIONS_REJECTED';
