import { combineReducers } from 'redux';

import {
  FORGOT_PASSWORD_FULFILLED,
  FORGOT_PASSWORD_PENDING,
  FORGOT_PASSWORD_REJECTED,
  GET_ME_FULFILLED,
  GET_ME_PENDING,
  GET_ME_REJECTED,
  LOGIN_USER_FULFILLED,
  LOGIN_USER_PENDING,
  LOGIN_USER_REJECTED,
  LOGOUT_USER_FULFILLED,
  LOGOUT_USER_PENDING,
  LOGOUT_USER_REJECTED,
  SIGNUP_USER_FULFILLED,
  SIGNUP_USER_PENDING,
  SIGNUP_USER_REJECTED,
} from '../types';

const INITIAL_STATE = {
  ME: {
    data: null,
    isFetching: false,
  },
  LOGIN: {
    data: null,
    isFetching: false,
  },
  LOGOUT: {
    message: null,
    isFetching: false,
  },
  SIGNUP: {
    message: null,
    isFetching: false,
  },
  FORGOT_PASSWORD: {
    message: null,
    isFetching: false,
  },
};

const me = (state = INITIAL_STATE.ME, action) => {
  switch (action.type) {
    case GET_ME_PENDING:
      return {
        ...INITIAL_STATE.ME,
        isFetching: true,
      };
    case GET_ME_FULFILLED:
      const { data } = action.payload;
      return {
        data,
        isFetching: false,
      };
    case GET_ME_REJECTED: {
      const { message } = action.payload;

      return {
        message,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};
const login = (state = INITIAL_STATE.LOGIN, action) => {
  switch (action.type) {
    case LOGIN_USER_PENDING:
      return {
        ...INITIAL_STATE.LOGIN,
        isFetching: true,
      };
    case LOGIN_USER_FULFILLED:
      return {
        data: action.payload,
        isFetching: false,
      };
    case LOGIN_USER_REJECTED: {
      const { message } = action.payload;

      return {
        message,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};
const signup = (state = INITIAL_STATE.SIGNUP, action) => {
  switch (action.type) {
    case SIGNUP_USER_PENDING:
      return {
        ...INITIAL_STATE.SIGNUP,
        isFetching: true,
      };
    case SIGNUP_USER_FULFILLED:
    case SIGNUP_USER_REJECTED: {
      const { message } = action.payload;
      return {
        message,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};
const logout = (state = INITIAL_STATE.LOGOUT, action) => {
  switch (action.type) {
    case LOGOUT_USER_PENDING:
      return {
        ...INITIAL_STATE.LOGOUT,
        isFetching: true,
      };
    case LOGOUT_USER_FULFILLED:
    case LOGOUT_USER_REJECTED: {
      const { message } = action.payload;

      return {
        message,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};
const forgotPassword = (state = INITIAL_STATE.FORGOT_PASSWORD, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_PENDING:
      return {
        ...INITIAL_STATE.FORGOT_PASSWORD,
        isFetching: true,
      };
    case FORGOT_PASSWORD_FULFILLED:
    case FORGOT_PASSWORD_REJECTED: {
      const { message } = action.payload;

      return {
        message,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  forgotPassword,
  login,
  logout,
  me,
  signup,
});
