import firebase from 'firebase/app';
import 'firebase/auth';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDp1ztbRhcFtRQnAYZSMjSSexsuOz2SnyE',
  authDomain: 'ptt-brain.firebaseapp.com',
  databaseURL: 'https://ptt-brain.firebaseio.com',
  projectId: 'ptt-brain',
  storageBucket: 'ptt-brain.appspot.com',
  messagingSenderId: '659465876278',
};

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_CONFIG);
} else {
   firebase.app(); // if already initialized, use that one
}

export const providers = {
  google: new firebase.auth.GoogleAuthProvider(),
  facebook: new firebase.auth.FacebookAuthProvider(),
};

export default firebase;
