import merge from 'lodash/merge';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';

import { forumSchema } from '../../schemas';
import {
  GET_FORUMS_FULFILLED,
  GET_FORUMS_PENDING,
  GET_FORUMS_REJECTED,
  GET_FORUM_GENDER_RATIO_FULFILLED,
  GET_FORUM_GENDER_RATIO_PENDING,
  GET_FORUM_GENDER_RATIO_REJECTED,
  GET_FORUM_HOT_POSTS_FULFILLED,
  GET_FORUM_HOT_POSTS_PENDING,
  GET_FORUM_HOT_POSTS_REJECTED,
  GET_FORUM_INFO_FULFILLED,
  GET_FORUM_INFO_PENDING,
  GET_FORUM_INFO_REJECTED,
  GET_FORUM_POSTS_FULFILLED,
  GET_FORUM_POSTS_PENDING,
  GET_FORUM_POSTS_REJECTED,
  GET_FORUM_SCHOOLS_FULFILLED,
  GET_FORUM_SCHOOLS_PENDING,
  GET_FORUM_SCHOOLS_REJECTED,
  GET_FORUM_SUBSCRIPTION_COUNTS_FULFILLED,
  GET_FORUM_SUBSCRIPTION_COUNTS_PENDING,
  GET_FORUM_SUBSCRIPTION_COUNTS_REJECTED,
  GET_FORUM_TOPICS_FULFILLED,
  GET_FORUM_TOPICS_PENDING,
  GET_FORUM_TOPICS_REJECTED,
  GET_FORUM_TRENDS_FULFILLED,
  GET_FORUM_TRENDS_PENDING,
  GET_FORUM_TRENDS_REJECTED,
} from '../../types';

const INITIAL_STATE = {
  ALL: {
    data: [],
    isFetching: false,
    error: null,
  },
  INFO: {
    forum: null,
    entities: {},
    isFetching: false,
    error: null,
  },
  CHART: {
    data: [],
    isFetching: false,
    error: null,
  },
  GENDER_RATIO: {
    data: [],
    isFetching: false,
    error: null,
  },
  TRENDS: {
    data: [],
    isFetching: false,
    error: null,
  },
  SUBSCRIPTION_COUNTS: {
    data: [],
    isFetching: false,
    error: null,
  },
  TOPICS: {
    data: [],
    isFetching: false,
    error: null,
  },
  SCHOOLS: {
    data: [],
    isFetching: false,
    error: null,
  },
  HOT_POSTS: {
    forum: null,
    data: [],
    sinceDaysAgo: 1,
    offset: 0,
    limit: 0,
    isFetching: false,
    error: null,
  },
  POSTS: {
    forum: null,
    data: [],
    offset: 0,
    limit: 0,
    isFetching: false,
    error: null,
  },
};

const all = (state = INITIAL_STATE.ALL, action) => {
  switch (action.type) {
    case GET_FORUMS_PENDING:
      return {
        ...INITIAL_STATE.ALL,
        isFetching: true,
      };
    case GET_FORUMS_FULFILLED: {
      const {
        data,
      } = action.payload;
      return {
        data,
        isFetching: false,
      };
    }
    case GET_FORUMS_REJECTED:
      return {
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const info = (state = INITIAL_STATE.INFO, action) => {
  switch (action.type) {
    case GET_FORUM_INFO_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_FORUM_INFO_FULFILLED: {
      const {
        data,
      } = action.payload;

      if (!data) {
        return INITIAL_STATE.INFO;
      }

      const {
        entities,
        result,
      } = normalize(data, forumSchema);

      return {
        entities: merge(state.entities, entities),
        forum: result,
        isFetching: false,
      };
    }
    case GET_FORUM_INFO_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const trends = (state = INITIAL_STATE.TRENDS, action) => {
  switch (action.type) {
    case GET_FORUM_TRENDS_PENDING:
      return {
        ...INITIAL_STATE.TRENDS,
        isFetching: true,
      };
    case GET_FORUM_TRENDS_FULFILLED: {
      const {
        data,
      } = action.payload;

      return {
        data,
        isFetching: false,
      };
    }
    case GET_FORUM_TRENDS_REJECTED:
      return {
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const topics = (state = INITIAL_STATE.TOPICS, action) => {
  switch (action.type) {
    case GET_FORUM_TOPICS_PENDING:
      return {
        ...INITIAL_STATE.TOPICS,
        isFetching: true,
      };
    case GET_FORUM_TOPICS_FULFILLED: {
      const {
        data,
      } = action.payload;

      return {
        data,
        isFetching: false,
      };
    }
    case GET_FORUM_TOPICS_REJECTED:
      return {
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const schools = (state = INITIAL_STATE.SCHOOLS, action) => {
  switch (action.type) {
    case GET_FORUM_SCHOOLS_PENDING:
      return {
        ...INITIAL_STATE.SCHOOLS,
        isFetching: true,
      };
    case GET_FORUM_SCHOOLS_FULFILLED: {
      const {
        data,
      } = action.payload;

      return {
        data,
        isFetching: false,
      };
    }
    case GET_FORUM_SCHOOLS_REJECTED:
      return {
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const genderRatio = (state = INITIAL_STATE.GENDER_RATIO, action) => {
  switch (action.type) {
    case GET_FORUM_GENDER_RATIO_PENDING:
      return {
        ...INITIAL_STATE.GENDER_RATIO,
        isFetching: true,
      };
    case GET_FORUM_GENDER_RATIO_FULFILLED: {
      const {
        data,
      } = action.payload;

      return {
        data,
        isFetching: false,
      };
    }
    case GET_FORUM_GENDER_RATIO_REJECTED:
      return {
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const subscriptionCounts = (state = INITIAL_STATE.SUBSCRIPTION_COUNTS, action) => {
  switch (action.type) {
    case GET_FORUM_SUBSCRIPTION_COUNTS_PENDING:
      return {
        ...INITIAL_STATE.SUBSCRIPTION_COUNTS,
        isFetching: true,
      };
    case GET_FORUM_SUBSCRIPTION_COUNTS_FULFILLED: {
      const {
        data,
      } = action.payload;

      return {
        data,
        isFetching: false,
      };
    }
    case GET_FORUM_SUBSCRIPTION_COUNTS_REJECTED:
      return {
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const posts = (state = INITIAL_STATE.POSTS, action) => {
  switch (action.type) {
    case GET_FORUM_POSTS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_FORUM_POSTS_FULFILLED: {
      const {
        forum,
        offset,
        limit,
      } = action.meta;
      const {
        data,
      } = action.payload;
      return {
        forum,
        data,
        offset,
        limit,
        isFetching: false,
      };
    }
    case GET_FORUM_POSTS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const hotPosts = (state = INITIAL_STATE.HOT_POSTS, action) => {
  switch (action.type) {
    case GET_FORUM_HOT_POSTS_PENDING:
      return {
        ...INITIAL_STATE.HOT_POSTS,
        isFetching: true,
      };
    case GET_FORUM_HOT_POSTS_FULFILLED: {
      const {
        forum,
        sinceDaysAgo,
        offset,
        limit,
      } = action.meta;
      const {
        data,
      } = action.payload;
      return {
        forum,
        data,
        sinceDaysAgo,
        offset,
        limit,
        isFetching: false,
      };
    }
    case GET_FORUM_HOT_POSTS_REJECTED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  all,
  genderRatio,
  hotPosts,
  info,
  posts,
  schools,
  subscriptionCounts,
  topics,
  trends,
});
