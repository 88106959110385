import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { responsiveStateReducer } from 'redux-responsive';

import article from './ptt/article';
import board from './ptt/board';
import user from './ptt/user';

import forum from './dcard/forum';
import persona from './dcard/persona';
import post from './dcard/post';
import school from './dcard/school';

import auth from './auth';
import dashboard from './dashboard';
import metric from './metric';
import search from './search';
import seo from './seo';

export default combineReducers({
  article,
  auth,
  board,
  browser: responsiveStateReducer,
  dashboard,
  firebase: firebaseReducer,
  forum,
  metric,
  persona,
  post,
  school,
  search,
  seo,
  user,
});
