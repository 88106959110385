export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_PENDING = 'GET_USER_INFO_PENDING';
export const GET_USER_INFO_FULFILLED = 'GET_USER_INFO_FULFILLED';
export const GET_USER_INFO_REJECTED = 'GET_USER_INFO_REJECTED';
export const GET_USER_LOCATIONS = 'GET_USER_LOCATIONS';
export const GET_USER_LOCATIONS_PENDING = 'GET_USER_LOCATIONS_PENDING';
export const GET_USER_LOCATIONS_FULFILLED = 'GET_USER_LOCATIONS_FULFILLED';
export const GET_USER_LOCATIONS_REJECTED = 'GET_USER_LOCATIONS_REJECTED';
export const GET_USER_INTERESTS = 'GET_USER_INTERESTS';
export const GET_USER_INTERESTS_PENDING = 'GET_USER_INTERESTS_PENDING';
export const GET_USER_INTERESTS_FULFILLED = 'GET_USER_INTERESTS_FULFILLED';
export const GET_USER_INTERESTS_REJECTED = 'GET_USER_INTERESTS_REJECTED';
export const GET_USER_PUSHES_STATS = 'GET_USER_PUSHES_STATS';
export const GET_USER_PUSHES_STATS_PENDING = 'GET_USER_PUSHES_STATS_PENDING';
export const GET_USER_PUSHES_STATS_FULFILLED = 'GET_USER_PUSHES_STATS_FULFILLED';
export const GET_USER_PUSHES_STATS_REJECTED = 'GET_USER_PUSHES_STATS_REJECTED';
export const GET_USER_RECENT_ACTIVITIES = 'GET_USER_RECENT_ACTIVITIES';
export const GET_USER_RECENT_ACTIVITIES_PENDING = 'GET_USER_RECENT_ACTIVITIES_PENDING';
export const GET_USER_RECENT_ACTIVITIES_FULFILLED = 'GET_USER_RECENT_ACTIVITIES_FULFILLED';
export const GET_USER_RECENT_ACTIVITIES_REJECTED = 'GET_USER_RECENT_ACTIVITIES_REJECTED';
export const GET_USER_DAILY_ACTIVITIES_DETAILS = 'GET_USER_DAILY_ACTIVITIES_DETAILS';
export const GET_USER_DAILY_ACTIVITIES_DETAILS_PENDING = 'GET_USER_DAILY_ACTIVITIES_DETAILS_PENDING';
export const GET_USER_DAILY_ACTIVITIES_DETAILS_FULFILLED = 'GET_USER_DAILY_ACTIVITIES_DETAILS_FULFILLED';
export const GET_USER_DAILY_ACTIVITIES_DETAILS_REJECTED = 'GET_USER_DAILY_ACTIVITIES_DETAILS_REJECTED';
export const GET_USER_ARTICLES = 'GET_USER_ARTICLES';
export const GET_USER_ARTICLES_PENDING = 'GET_USER_ARTICLES_PENDING';
export const GET_USER_ARTICLES_FULFILLED = 'GET_USER_ARTICLES_FULFILLED';
export const GET_USER_ARTICLES_REJECTED = 'GET_USER_ARTICLES_REJECTED';
export const GET_RELATED_USER_IDS = 'GET_RELATED_USER_IDS';
export const GET_RELATED_USER_IDS_PENDING = 'GET_RELATED_USER_IDS_PENDING';
export const GET_RELATED_USER_IDS_FULFILLED = 'GET_RELATED_USER_IDS_FULFILLED';
export const GET_RELATED_USER_IDS_REJECTED = 'GET_RELATED_USER_IDS_REJECTED';
export const GET_USER_FANS = 'GET_USER_FANS';
export const GET_USER_FANS_PENDING = 'GET_USER_FANS_PENDING';
export const GET_USER_FANS_FULFILLED = 'GET_USER_FANS_FULFILLED';
export const GET_USER_FANS_REJECTED = 'GET_USER_FANS_REJECTED';
